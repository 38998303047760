@import url(https://fonts.googleapis.com/css?family=Hind:400,700|Open+Sans&display=swap);
:root {
  --blue: #26c1fc;
  --blue-focus: #26acfc;
  --pink: #fe82de;
  --yella: #ffc215;
  --black: #2e2e2e;
  --white: white;

  --silver: #e2eaf2;
  --silver-focus: #d5dee6;
  --silver-text: #818f9d;
  --dark-grey: #707070;

  --radius-xs: 3px;
  --radius-s: 4px;
  --radius-m: 6px;

  --xs: 4px;
  --s: 8px;
  --m: 16px;
  --l: 24px;
  --xl: 32px;
  --xxl: 40px;

  --headerFont: "Avenir Next", "Hind", sans-serif;
  --bodyFont: "Open Sans", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Avenir Next", "Hind", sans-serif;
  font-family: var(--headerFont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Home-yellow {
  color: var(--yella);
}

.Home-logo {
  max-width: 160px;
  margin: 1em;
}

.Home-header {
  font-family: var(--headerFont);
  font-weight: 700;
  font-size: 4em;
  line-height: 1em;
  margin: 0;
}

.Home-descript {
  font-size: 1.2em;
  margin: 1em auto 2em;
  max-width: 520px;
}

@media screen and (max-width: 720px) {
  .Home-header {
    font-size: 2.4em;
  }

  .Home-descript {
    font-size: 1em;
  }

  .Home-container {
    margin-top: 10vh;
  }
}

.u-flex {
  display: flex;
}

.u-flexWrap {
  flex-wrap: wrap;
}

.u-flexColumn {
  flex-direction: column;
}

.u-flex-justifyCenter {
  justify-content: center;
}

.u-flex-alignCenter {
  align-items: center;
}

.u-inlineBlock {
  display: inline-block;
}

.u-bold {
  font-weight: 600;
}

.u-textCenter {
  text-align: center;
}

.u-relative {
  position: relative;
}

.u-pointer {
  cursor: pointer;
}

.u-blue {
  color: var(--blue);
}

.u-pink {
  color: var(--pink);
}

.u-yellow {
  color: var(--yella);
}

.u-grey {
  color: var(--grey-text);
}

.u-darkGrey {
  color: var(--dark-grey);
}

.u-black {
  color: var(--a-black);
}

.u-white {
  color: var(--a-white);
}

.u-bold {
  font-weight: 700;
}

.u-marginNone {
  margin: 0;
}

